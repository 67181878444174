import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  IconButton,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { createEntity, deleteEntity, getEntities, reset } from 'app/entities/user-authority-resource/user-authority-resource.reducer';
import { IUserAuthorityResource } from 'app/shared/model/user-authority-resource.model';
import { IPrayerWalkEvent } from 'app/shared/model/prayer-walk-event.model';
import { AUTHORITIES } from 'app/config/constants';

interface PrayerChampionsProps {
  prayerWalkEvent: IPrayerWalkEvent;
  inactiveSubscription: boolean;
}

export const PrayerChampions = ({ prayerWalkEvent, inactiveSubscription }: PrayerChampionsProps) => {
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState('');
  const [error, setError] = useState<string | null>(null);

  const prayerChampions = useAppSelector(state => state.userAuthorityResource.entities);
  const updateSuccess = useAppSelector(state => state.userAuthorityResource.updateSuccess);
  const updating = useAppSelector(state => state.userAuthorityResource.updating);
  const errorMessage = useAppSelector(state => state.userAuthorityResource.errorMessage);

  const loadPrayerChampions = () => {
    console.log('getting prayer champions');
    dispatch(
      getEntities({
        'resourceType.equals': 'prayerWalkEvent',
        'resourceId.equals': prayerWalkEvent.id,
        'authorityName.equals': AUTHORITIES.PRAYER_CHAMPION,
      })
    );
  };

  useEffect(() => {
    if (prayerWalkEvent && prayerWalkEvent.id) {
      loadPrayerChampions();
    }
  }, [prayerWalkEvent]);

  useEffect(() => {
    if (updateSuccess) {
      loadPrayerChampions();
      if (email) {
        setEmail('');
        setError(null);
      }
      dispatch(reset());
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (errorMessage === 'User not found') {
      setError('A user with this email does not exist, please ask them to register an account.');
    }
  }, [errorMessage]);

  const addPrayerChampion = () => {
    if (!email) return;
    const resource: IUserAuthorityResource = {
      userEmail: email,
      authorityName: AUTHORITIES.PRAYER_CHAMPION,
      resourceType: 'prayerWalkEvent',
      resourceId: prayerWalkEvent.id,
    };
    dispatch(createEntity(resource));
  };

  const removePrayerChampion = (id: number) => {
    dispatch(deleteEntity(id));
  };

  return (
    <Box bg="white" p={4} w="full">
      <Flex mb={4} justify="space-between" align="center">
        <Heading size="md">Prayer Champions</Heading>
        <Flex grow={1}></Flex>
        <Flex gap={2} grow={2}>
          <FormControl isInvalid={!!error}>
            <Input
              placeholder="Enter email"
              value={email}
              onChange={e => {
                setEmail(e.target.value);
                setError(null);
              }}
              disabled={inactiveSubscription || updating}
            />
            {error && <FormErrorMessage>{error}</FormErrorMessage>}
          </FormControl>
          <Button colorScheme="primary" onClick={addPrayerChampion} isLoading={updating} disabled={inactiveSubscription || updating}>
            Add
          </Button>
        </Flex>
      </Flex>

      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Email</Th>
            <Th w={4}>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {prayerChampions.map((prayerChampion: IUserAuthorityResource) => (
            <Tr key={prayerChampion.id}>
              <Td>{prayerChampion.userEmail}</Td>
              <Td>
                <IconButton
                  aria-label="Remove prayer champion"
                  icon={<DeleteIcon />}
                  colorScheme="red"
                  size="sm"
                  onClick={() => removePrayerChampion(prayerChampion.id)}
                  disabled={inactiveSubscription || updating}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};
