/**
 * Hook to check if a feature flag is enabled
 * @param flagName The name of the feature flag to check
 * @returns boolean indicating if the feature is enabled
 */
export const useFeatureFlag = (flagName: string): boolean => {
  switch (flagName) {
    case 'googleMapsSearchChurchEnabled':
      return REACT_APP_GOOGLE_MAPS_SEARCH_CHURCH_ENABLED === 'true';
    default:
      return false;
  }
};
