import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, FormControl, FormLabel, Input, Textarea, VStack, Box, FormErrorMessage } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import {
  getEntity,
  updateEntity,
  createEntity,
  reset as resetEntity,
} from 'app/entities/community-announcement/community-announcement.reducer';
import { useCurrentEvent } from 'app/shared/hooks/useCurrentEvent';
import { useForm } from 'react-hook-form';

type FormValues = {
  title: string;
  body: string;
  eventDate: string | null;
};

export const CommunityAnnouncementUpdate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentPrayerWalkEvent = useCurrentEvent();
  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const entity = useAppSelector(state => state.communityAnnouncement.entity);
  const loading = useAppSelector(state => state.communityAnnouncement.loading);
  const updating = useAppSelector(state => state.communityAnnouncement.updating);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      title: entity?.title || '',
      body: entity?.body || '',
      eventDate: entity?.eventDate || null,
    },
  });

  useEffect(() => {
    if (!isNew && currentPrayerWalkEvent) {
      dispatch(getEntity({ id, prayerWalkEventId: currentPrayerWalkEvent.id }));
    }
    return () => {
      dispatch(resetEntity());
    };
  }, [id, currentPrayerWalkEvent]);

  useEffect(() => {
    if (entity) {
      reset(entity);
    }
  }, [entity]);

  const handleClose = () => {
    navigate('/announcements');
  };

  const onSubmit = (values: FormValues) => {
    if (!currentPrayerWalkEvent) return;

    const entityToSave = {
      ...values,
      id: isNew ? undefined : entity.id,
      prayerWalkEvent: { id: currentPrayerWalkEvent.id },
    };

    const promise = isNew
      ? dispatch(createEntity({ entity: entityToSave, prayerWalkEventId: currentPrayerWalkEvent.id }))
      : dispatch(updateEntity({ entity: entityToSave, prayerWalkEventId: currentPrayerWalkEvent.id }));

    promise.then(() => {
      handleClose();
    });
  };

  return (
    <Box maxW="3xl" mx="auto" p={4}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={4} align="stretch">
          <FormControl isInvalid={!!errors.title}>
            <FormLabel>Title</FormLabel>
            <Input
              {...register('title', {
                required: 'Title is required',
                minLength: { value: 3, message: 'Title must be at least 3 characters' },
              })}
            />
            <FormErrorMessage>{errors.title?.message}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.body}>
            <FormLabel>Body</FormLabel>
            <Textarea
              {...register('body', {
                required: 'Body is required',
                minLength: { value: 10, message: 'Body must be at least 10 characters' },
              })}
            />
            <FormErrorMessage>{errors.body?.message}</FormErrorMessage>
          </FormControl>

          <FormControl>
            <FormLabel>Event Date</FormLabel>
            <Input type="date" {...register('eventDate')} />
          </FormControl>

          <Button type="submit" colorScheme="blue" isLoading={updating} isDisabled={loading}>
            {isNew ? 'Create' : 'Update'}
          </Button>
          <Button variant="ghost" onClick={handleClose}>
            Cancel
          </Button>
        </VStack>
      </form>
    </Box>
  );
};

export default CommunityAnnouncementUpdate;
